import { React, useState } from "react";
import { BiRightArrowAlt } from "react-icons/bi";

export default function ColMapNested({
  column,
  itno,
  parentItno,
  items,
  getMappedOption,
}) {
  const [value, setValue] = useState("");
  let arrowStyles = { padding: "2px", color: "#9ca3af", fontSize: "30px" };

  const handleChange = (e) => {
    let changedValue = e.target.value;
    setValue(changedValue);
    getMappedOption(parentItno, itno, changedValue);
  };

  return (
    <>
      <style>
        {`
        select {
                 -webkit-appearance: none;
                 -moz-appearance: none;
                 background: transparent;
                 background-image: url("data:image/svg+xml;utf8,<svg fill='%230000A3' height='24' padding-top='10' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
                 background-repeat: no-repeat;
                 background-position-x: 100%;
                 background-position-y: 12px;
                 border: 1px solid #dfdfdf;
                 border-radius: 2px;
            }
          `}
      </style>
      <div className="flex items-center py-2">
        <div className="flex border border-gray-400 w-[250px] h-[50px]">
          <div
            className={`my-1 mx-2 w-3 ${
              value === "Ignore"
                ? "bg-[#C5AB22]"
                : value.length > 0
                ? "bg-[#22C55E]"
                : "bg-[#A30000]"
            }`}
          />
          <p className="capitalize py-3 text-gray-400 pl-16 text-center">
            {column.length > 15 ? column.slice(0, 15) + "..." : column}
          </p>
        </div>
        <div className="mx-4">
          {/*"-->"*/}
          <BiRightArrowAlt style={arrowStyles} />
        </div>
        <div>
          <select
            className="flex border text-gray-400 border-gray-400 w-[250px] h-[50px] bg-white px-4 mr-2"
            value={value}
            onChange={handleChange}
            key={itno}
          >
            <option value="">Select a column name</option>
            <option value="Ignore">Ignore</option>
            {items.map((item, index) => (
              <option value={item.option} key={index}>
                {item.optionDisplayName}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}
// j
//               <option value={item.option} key={index}>
//                 {item.optionDisplayName}
//               </option>
