/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const BenefitPlanCoverageAmountfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation BenefitPlanCoverageAmountfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    BenefitPlanCoverageAmountfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const BenefitPlanOverviewfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation BenefitPlanOverviewfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    BenefitPlanOverviewfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const InsuredBenefitsPlanOverviewfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation InsuredBenefitsPlanOverviewfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    InsuredBenefitsPlanOverviewfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const PharmacyUsageBasedCostSharingfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation PharmacyUsageBasedCostSharingfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    PharmacyUsageBasedCostSharingfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const PlanPayCheckContributionsfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation PlanPayCheckContributionsfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    PlanPayCheckContributionsfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const PolicyRidersfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation PolicyRidersfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    PolicyRidersfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const SavingsPlanOverviewfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation SavingsPlanOverviewfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    SavingsPlanOverviewfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const UsDentalVisionStdOverviewfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation UsDentalVisionStdOverviewfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    UsDentalVisionStdOverviewfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const UsMedicalPlanHraAccountsfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation UsMedicalPlanHraAccountsfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    UsMedicalPlanHraAccountsfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const UsMedicalPlanHsaAccountsfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation UsMedicalPlanHsaAccountsfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    UsMedicalPlanHsaAccountsfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const UsMedicalPlanOverviewfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation UsMedicalPlanOverviewfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    UsMedicalPlanOverviewfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const UsageBasedCostSharingfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation UsageBasedCostSharingfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    UsageBasedCostSharingfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const EmployeeEligibilityfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation EmployeeEligibilityfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    EmployeeEligibilityfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const AdultDependentEligbilityfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation AdultDependentEligbilityfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    AdultDependentEligbilityfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const ChildDependentEligibilityfilterBenefitPlanAndCountry = /* GraphQL */ `
  mutation ChildDependentEligibilityfilterBenefitPlanAndCountry(
    $benefit_id: String
    $country: String
  ) {
    ChildDependentEligibilityfilterBenefitPlanAndCountry(
      benefit_id: $benefit_id
      country: $country
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_id_display_name
      component_type
      component_type_display_name
      country
      __typename
    }
  }
`;
export const OrganizationUserIdFilter = /* GraphQL */ `
  mutation OrganizationUserIdFilter($userid: String) {
    OrganizationUserIdFilter(userid: $userid) {
      organization_id
      organization_name
      organization_type
      organization_type_display_name
      userid
      __typename
    }
  }
`;
export const BenefitInventoryUserIdFilter = /* GraphQL */ `
  mutation BenefitInventoryUserIdFilter($userid: String) {
    BenefitInventoryUserIdFilter(userid: $userid) {
      company_id
      company_name
      company_country_hq
      industry_id
      industry_name
      sub_industry_id
      sub_industry_name
      benefit_plan_id
      benefit_plan_display_name
      benefit_status
      benefit_id
      benefit_program_display_name
      benefit_category
      benefit_sub_category
      country
      two_digit_iso_code
      country_name
      region
      sub_region
      intermediate_region
      userid
      load_dt
      __typename
    }
  }
`;
export const IntegrateTemplateOverviewFilter = /* GraphQL */ `
  mutation IntegrateTemplateOverviewFilter($userid: String) {
    IntegrateTemplateOverviewFilter(userid: $userid) {
      as_of_date
      template_id
      template_status
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      company_id_status
      country_code
      country_name
      region_name
      sub_region_name
      intermediate_region_name
      userid
      rn
      data_source
      __typename
    }
  }
`;
export const IntegrateTemplateFilter = /* GraphQL */ `
  mutation IntegrateTemplateFilter($userid: String) {
    IntegrateTemplateFilter(userid: $userid) {
      as_of_date
      template_id
      template_status
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      company_id_status
      country_code
      country_name
      region_name
      sub_region_name
      intermediate_region_name
      userid
      rn
      data_source
      __typename
    }
  }
`;
export const IntegrateTemplateEformFilter = /* GraphQL */ `
  mutation IntegrateTemplateEformFilter($userid: String) {
    IntegrateTemplateEformFilter(userid: $userid) {
      as_of_date
      template_id
      template_status
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      company_id_status
      country_code
      country_name
      region_name
      sub_region_name
      intermediate_region_name
      userid
      rn
      data_source
      __typename
    }
  }
`;
export const IntegrateTemplateAttributeFilter = /* GraphQL */ `
  mutation IntegrateTemplateAttributeFilter($template_type_id: String) {
    IntegrateTemplateAttributeFilter(template_type_id: $template_type_id) {
      as_of_date
      attribute_key_description
      attribute_key_display_name
      attribute_key_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      component_source_table
      component_type
      template_type_id
      __typename
    }
  }
`;
export const ReportRunFilter = /* GraphQL */ `
  mutation ReportRunFilter($userid: String) {
    ReportRunFilter(userid: $userid) {
      report_request_id
      company_id
      company_name
      organization_id
      report_type_display_name
      report_type
      create_userid
      status_timestamp
      job_status
      file_size
      file_url
      __typename
    }
  }
`;
export const IntegrateBenefitPlanIdFilter = /* GraphQL */ `
  mutation IntegrateBenefitPlanIdFilter(
    $company_id: String
    $country: String
    $template_type_id: String
  ) {
    IntegrateBenefitPlanIdFilter(
      company_id: $company_id
      country: $country
      template_type_id: $template_type_id
    ) {
      template_type_id
      company_id
      country
      benefit_id
      benefit_program_display_name
      benefit_plan_id
      benefit_plan_display_name
      __typename
    }
  }
`;
export const CompanyListUseridFilter = /* GraphQL */ `
  mutation CompanyListUseridFilter($userid: String) {
    CompanyListUseridFilter(userid: $userid) {
      as_of_date
      company_country_hq
      company_id
      company_id_status
      company_name
      company_state_hq
      effective_date
      filename
      industry_id
      industry_name
      is_exclude_from_benchmarks
      load_dt
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      employer_size
      employer_size_display_name
      organization_id
      organization_name
      userid
      __typename
    }
  }
`;
export const IntegrateFileLogOverviewFilter = /* GraphQL */ `
  mutation IntegrateFileLogOverviewFilter($userid: String) {
    IntegrateFileLogOverviewFilter(userid: $userid) {
      company_id
      company_name
      etl_functional_process_id
      file_id
      filename
      load_dt
      message
      status
      submission_type
      template_display_name
      template_id
      template_type_display_name
      template_type_id
      __typename
    }
  }
`;
export const StandardReportFilter = /* GraphQL */ `
  mutation StandardReportFilter($report_type: String) {
    StandardReportFilter(report_type: $report_type) {
      report_category_display_name
      report_category
      report_category_sort_order
      report_type_display_name
      report_type_sort_order
      report_type
      component_source_table
      filter_source_table
      component_target_table
      operator
      operator_value
      operator_display_name
      filter
      filter_display_name
      attribute_key_value_type
      filter_value
      filter_value_display_name
      filter_display_name_sort_order
      __typename
    }
  }
`;
export const FilterUpdateCompanyDetails = /* GraphQL */ `
  mutation FilterUpdateCompanyDetails($company_id: String) {
    FilterUpdateCompanyDetails(company_id: $company_id) {
      as_of_date
      company_country_hq
      company_country_hq_name
      company_id
      company_status
      company_name
      company_state_hq
      company_state_hq_name
      industry_id
      industry_name
      is_exclude_from_benchmarks
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      employer_size
      employer_size_display_name
      organization_id
      organization_name
      __typename
    }
  }
`;
export const createeFormFileUploadLog = /* GraphQL */ `
  mutation CreateeFormFileUploadLog(
    $company_id: String
    $template_id: String
    $transaction_id: String
    $filename: String
  ) {
    createeFormFileUploadLog(
      company_id: $company_id
      template_id: $template_id
      transaction_id: $transaction_id
      filename: $filename
    ) {
      company_id
      template_id
      transaction_id
      filename
      load_dt
      __typename
    }
  }
`;
export const createDesignFileUploadLog = /* GraphQL */ `
  mutation CreateDesignFileUploadLog(
    $company_id: String
    $benefit_plan_id: String
    $filename: String
  ) {
    createDesignFileUploadLog(
      company_id: $company_id
      benefit_plan_id: $benefit_plan_id
      filename: $filename
    ) {
      company_id
      benefit_plan_id
      filename
      load_dt
      __typename
    }
  }
`;
export const createBenefitDetails = /* GraphQL */ `
  mutation CreateBenefitDetails(
    $benefit_details_data: [createBenefitDetailsInput]
  ) {
    createBenefitDetails(benefit_details_data: $benefit_details_data)
  }
`;
export const createeFormDetails = /* GraphQL */ `
  mutation CreateeFormDetails($eform_details_data: [createeFormDetailsInput]) {
    createeFormDetails(eform_details_data: $eform_details_data)
  }
`;
export const updateBenefitDetails = /* GraphQL */ `
  mutation UpdateBenefitDetails(
    $as_of_date: String
    $attribute_category: String
    $attribute_key: String
    $attribute_value: String
    $benefit_id: String
    $benefit_plan_id: String
    $benefit_plan_display_name: String
    $benefit_status: String
    $company_id: String
    $country: String
    $operator_id: String
    $userid: String
  ) {
    updateBenefitDetails(
      as_of_date: $as_of_date
      attribute_category: $attribute_category
      attribute_key: $attribute_key
      attribute_value: $attribute_value
      benefit_id: $benefit_id
      benefit_plan_id: $benefit_plan_id
      benefit_plan_display_name: $benefit_plan_display_name
      benefit_status: $benefit_status
      company_id: $company_id
      country: $country
      operator_id: $operator_id
      userid: $userid
    ) {
      as_of_date
      attribute_key_description_child
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_list_valid_value_child
      attribute_list_valid_value_parent
      attribute_list_value_display_name_child
      attribute_list_value_display_name_parent
      attribute_list_value_sort_order_child
      attribute_list_value_sort_order_parent
      benefit_id
      child_operator_flag
      component_id
      component_source_table
      component_target_table
      component_type
      country
      user_id
      __typename
    }
  }
`;
export const createCompany = /* GraphQL */ `
  mutation CreateCompany(
    $company_country_hq: String
    $company_id_status: String
    $company_name: String
    $company_state_hq: String
    $industry_id: String
    $is_exclude_from_benchmarks: String
    $poc_email: String
    $poc_role: String
    $employer_size: String
    $organization_id: String
    $primary_poc: String
    $sub_industry_id: String
    $userid: String
  ) {
    createCompany(
      company_country_hq: $company_country_hq
      company_id_status: $company_id_status
      company_name: $company_name
      company_state_hq: $company_state_hq
      industry_id: $industry_id
      is_exclude_from_benchmarks: $is_exclude_from_benchmarks
      poc_email: $poc_email
      poc_role: $poc_role
      employer_size: $employer_size
      organization_id: $organization_id
      primary_poc: $primary_poc
      sub_industry_id: $sub_industry_id
      userid: $userid
    ) {
      as_of_date
      company_country_hq
      company_country_hq_intermediate_region
      company_country_hq_name
      company_country_hq_region_name
      company_country_hq_sub_region_name
      company_country_hq_two_digit_iso_code
      company_id
      company_name
      company_state_hq
      company_state_hq_name
      company_status
      industry_id
      industry_name
      is_exclude_from_benchmarks
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      employer_size
      employer_size_display_name
      oranization_id
      organization_name
      userid
      __typename
    }
  }
`;
export const createIntegrateTemplateAttributeDetails = /* GraphQL */ `
  mutation CreateIntegrateTemplateAttributeDetails(
    $create_template_params: [createIntegrateTemplateAttributeDetailsInput]
    $file_id: String
  ) {
    createIntegrateTemplateAttributeDetails(
      create_template_params: $create_template_params
      file_id: $file_id
    ) {
      generated_template_id
      lambda_status
      new_file_key
      source_column_names
      __typename
    }
  }
`;
export const createIntegrateTemplateColumnMap = /* GraphQL */ `
  mutation CreateIntegrateTemplateColumnMap(
    $create_column_map_data: [createIntegrateTemplateColumnMapInput]
    $file_id: String
  ) {
    createIntegrateTemplateColumnMap(
      create_column_map_data: $create_column_map_data
      file_id: $file_id
    ) {
      key
      keyDisplayName
      source_columns {
        column
        source_values
        __typename
      }
      __typename
    }
  }
`;
export const createIntegrateTemplateKeyValueMap = /* GraphQL */ `
  mutation CreateIntegrateTemplateKeyValueMap(
    $key_value_map_data: [createIntegrateTemplateKeyValueMapInput]
  ) {
    createIntegrateTemplateKeyValueMap(key_value_map_data: $key_value_map_data)
  }
`;
export const createIntegrateUploadDetails = /* GraphQL */ `
  mutation CreateIntegrateUploadDetails(
    $upload_metadata: createIntegrateUploadDetailsInput
  ) {
    createIntegrateUploadDetails(upload_metadata: $upload_metadata)
  }
`;
export const createInvoiceSource = /* GraphQL */ `
  mutation CreateInvoiceSource(
    $administrator_comments: String
    $amount: String
    $begin_date: String
    $benefit_id: String
    $company_id: String
    $cost_category: String
    $cost_type: String
    $country: String
    $currency_code: String
    $end_date: String
    $ingestion_method: String
    $sub_plan_id: String
    $template_id: String
    $template_type_id: String
    $userid: String
    $vendor: String
  ) {
    createInvoiceSource(
      administrator_comments: $administrator_comments
      amount: $amount
      begin_date: $begin_date
      benefit_id: $benefit_id
      company_id: $company_id
      cost_category: $cost_category
      cost_type: $cost_type
      country: $country
      currency_code: $currency_code
      end_date: $end_date
      ingestion_method: $ingestion_method
      sub_plan_id: $sub_plan_id
      template_id: $template_id
      template_type_id: $template_type_id
      userid: $userid
      vendor: $vendor
    ) {
      administrator_comments
      amount
      benefit_display_name
      benefit_id
      company_id
      company_name
      cost_category
      cost_category_display_name
      cost_type
      cost_type_display_name
      country
      country_display_name
      currency_code
      eform_destination_table
      sub_plan_id
      sub_plan_id_display_name
      template_display_name
      template_id
      template_type_display_name
      template_type_id
      userid
      vendor
      __typename
    }
  }
`;
export const createPublishedDemographicProviderReconDetails = /* GraphQL */ `
  mutation CreatePublishedDemographicProviderReconDetails(
    $publish_recon_data: [createPublishedDemographicProviderReconDetailsInput]
    $user_id: String
  ) {
    createPublishedDemographicProviderReconDetails(
      publish_recon_data: $publish_recon_data
      user_id: $user_id
    )
  }
`;
export const createReportReconEnrollmentSysToProviderPublishedDetails = /* GraphQL */ `
  mutation CreateReportReconEnrollmentSysToProviderPublishedDetails(
    $publish_recon_data: [createReportReconEnrollmentSysToProviderPublishedDetailsInput]
    $user_id: String
  ) {
    createReportReconEnrollmentSysToProviderPublishedDetails(
      publish_recon_data: $publish_recon_data
      user_id: $user_id
    )
  }
`;
export const createReportReconFundingDepositsToPayrollPublishedDetails = /* GraphQL */ `
  mutation CreateReportReconFundingDepositsToPayrollPublishedDetails(
    $publish_recon_data: [createReportReconFundingDepositsToPayrollPublishedDetailsInput]
    $user_id: String
  ) {
    createReportReconFundingDepositsToPayrollPublishedDetails(
      publish_recon_data: $publish_recon_data
      user_id: $user_id
    )
  }
`;
export const createReportFilter = /* GraphQL */ `
  mutation CreateReportFilter($report_filter_data: [createReportFilterInput]) {
    createReportFilter(report_filter_data: $report_filter_data)
  }
`;
export const createReportInput = /* GraphQL */ `
  mutation CreateReportInput(
    $company_id: String
    $report_request_id: String
    $report_type: String
    $report_id: String
    $is_select_all_column: String
    $column_list: [String]
    $granularity: String
    $aggregated_column_list: [String]
    $create_userid: String
    $is_delete: String
    $delete_userid: String
  ) {
    createReportInput(
      company_id: $company_id
      report_request_id: $report_request_id
      report_type: $report_type
      report_id: $report_id
      is_select_all_column: $is_select_all_column
      column_list: $column_list
      granularity: $granularity
      aggregated_column_list: $aggregated_column_list
      create_userid: $create_userid
      is_delete: $is_delete
      delete_userid: $delete_userid
    )
  }
`;
export const createeForm = /* GraphQL */ `
  mutation CreateeForm(
    $attribute_key: String
    $attribute_value: String
    $benefit_id: String
    $company_id: String
    $template_display_name: String
    $template_status: String
    $template_type_id: String
    $userid: String
  ) {
    createeForm(
      attribute_key: $attribute_key
      attribute_value: $attribute_value
      benefit_id: $benefit_id
      company_id: $company_id
      template_display_name: $template_display_name
      template_status: $template_status
      template_type_id: $template_type_id
      userid: $userid
    ) {
      as_of_date
      attribute_array_value
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_value_display_name
      attribute_valid_value
      benefit_display_name
      benefit_id
      company_id
      company_id_status
      company_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      __typename
    }
  }
`;
export const updateeForm = /* GraphQL */ `
  mutation UpdateeForm(
    $attribute_key: String
    $attribute_value: String
    $benefit_id: String
    $company_id: String
    $template_id: String
    $template_display_name: String
    $template_status: String
    $template_type_id: String
    $userid: String
  ) {
    updateeForm(
      attribute_key: $attribute_key
      attribute_value: $attribute_value
      benefit_id: $benefit_id
      company_id: $company_id
      template_id: $template_id
      template_display_name: $template_display_name
      template_status: $template_status
      template_type_id: $template_type_id
      userid: $userid
    ) {
      as_of_date
      attribute_array_value
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_value_display_name
      attribute_valid_value
      benefit_display_name
      benefit_id
      company_id
      company_id_status
      company_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      __typename
    }
  }
`;
export const createreadReportReconDetails = /* GraphQL */ `
  mutation CreatereadReportReconDetails(
    $company_id: String
    $company_status: String
    $compay_name: String
    $create_userid: String
    $created_dt: String
    $delete_userid: String
    $deleted_dt: String
    $input_file_id_1: String
    $input_file_id_1_name: String
    $input_file_id_1_template_id: String
    $input_file_id_1_template_name: String
    $input_file_id_1_template_type_id: String
    $input_file_id_1_template_type_name: String
    $input_file_id_2: String
    $input_file_id_2_name: String
    $input_file_id_2_template_id: String
    $input_file_id_2_template_name: String
    $input_file_id_2_template_type_id: String
    $input_file_id_2_template_type_name: String
    $is_delete: String
    $last_publish_dt: String
    $publish_status: String
    $publish_userid: String
    $recon_type: String
    $recon_type_display_name: String
    $report_request_id: String
  ) {
    createreadReportReconDetails(
      company_id: $company_id
      company_status: $company_status
      compay_name: $compay_name
      create_userid: $create_userid
      created_dt: $created_dt
      delete_userid: $delete_userid
      deleted_dt: $deleted_dt
      input_file_id_1: $input_file_id_1
      input_file_id_1_name: $input_file_id_1_name
      input_file_id_1_template_id: $input_file_id_1_template_id
      input_file_id_1_template_name: $input_file_id_1_template_name
      input_file_id_1_template_type_id: $input_file_id_1_template_type_id
      input_file_id_1_template_type_name: $input_file_id_1_template_type_name
      input_file_id_2: $input_file_id_2
      input_file_id_2_name: $input_file_id_2_name
      input_file_id_2_template_id: $input_file_id_2_template_id
      input_file_id_2_template_name: $input_file_id_2_template_name
      input_file_id_2_template_type_id: $input_file_id_2_template_type_id
      input_file_id_2_template_type_name: $input_file_id_2_template_type_name
      is_delete: $is_delete
      last_publish_dt: $last_publish_dt
      publish_status: $publish_status
      publish_userid: $publish_userid
      recon_type: $recon_type
      recon_type_display_name: $recon_type_display_name
      report_request_id: $report_request_id
    ) {
      company_id
      create_userid
      created_dt
      input_file_id_1
      input_file_id_2
      recon_type
      report_request_id
      __typename
    }
  }
`;
export const createeFormGeneralUsageDetails = /* GraphQL */ `
  mutation CreateeFormGeneralUsageDetails(
    $support_url: String
    $begin_date: String
    $end_date: String
    $template_type_id: String
    $template_id: String
    $benefit_id: String
    $company_id: String
    $country: String
    $vendor: String
    $metric_type: String
    $usage_type: String
    $spend: String
    $claim_volume: String
    $ingestion_method: String
    $userid: String
  ) {
    createeFormGeneralUsageDetails(
      support_url: $support_url
      begin_date: $begin_date
      end_date: $end_date
      template_type_id: $template_type_id
      template_id: $template_id
      benefit_id: $benefit_id
      company_id: $company_id
      country: $country
      vendor: $vendor
      metric_type: $metric_type
      usage_type: $usage_type
      spend: $spend
      claim_volume: $claim_volume
      ingestion_method: $ingestion_method
      userid: $userid
    ) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const UpdateeFormGeneralUsageDetails = /* GraphQL */ `
  mutation UpdateeFormGeneralUsageDetails(
    $transaction_id: String
    $support_url: String
    $begin_date: String
    $end_date: String
    $template_type_id: String
    $template_id: String
    $benefit_id: String
    $company_id: String
    $country: String
    $vendor: String
    $metric_type: String
    $usage_type: String
    $spend: String
    $claim_volume: String
    $ingestion_method: String
    $userid: String
  ) {
    UpdateeFormGeneralUsageDetails(
      transaction_id: $transaction_id
      support_url: $support_url
      begin_date: $begin_date
      end_date: $end_date
      template_type_id: $template_type_id
      template_id: $template_id
      benefit_id: $benefit_id
      company_id: $company_id
      country: $country
      vendor: $vendor
      metric_type: $metric_type
      usage_type: $usage_type
      spend: $spend
      claim_volume: $claim_volume
      ingestion_method: $ingestion_method
      userid: $userid
    ) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const createeFormHeadcountDetails = /* GraphQL */ `
  mutation CreateeFormHeadcountDetails(
    $support_url: String
    $begin_date: String
    $end_date: String
    $template_type_id: String
    $template_id: String
    $benefit_id: String
    $company_id: String
    $country: String
    $vendor: String
    $headcount_type: String
    $value: String
    $ingestion_method: String
    $userid: String
  ) {
    createeFormHeadcountDetails(
      support_url: $support_url
      begin_date: $begin_date
      end_date: $end_date
      template_type_id: $template_type_id
      template_id: $template_id
      benefit_id: $benefit_id
      company_id: $company_id
      country: $country
      vendor: $vendor
      headcount_type: $headcount_type
      value: $value
      ingestion_method: $ingestion_method
      userid: $userid
    ) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const eFormfileTemplateTypeID = /* GraphQL */ `
  mutation EFormfileTemplateTypeID(
    $benefit_id: String
    $template_type_id: String
  ) {
    eFormfileTemplateTypeID(
      benefit_id: $benefit_id
      template_type_id: $template_type_id
    ) {
      as_of_date
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_parent
      attribute_key_value_type
      attribute_list_valid_value_child
      attribute_list_value_display_name_child
      attribute_list_value_sort_order_child
      benefit_id
      component_id
      component_source_table
      component_target_table
      list_type
      template_type_id
      __typename
    }
  }
`;
export const filterBenefitPlanAndCountry = /* GraphQL */ `
  mutation FilterBenefitPlanAndCountry($benefit_id: String, $country: String) {
    filterBenefitPlanAndCountry(benefit_id: $benefit_id, country: $country) {
      as_of_date
      attribute_key_description_child
      attribute_key_description_parent
      attribute_key_display_name_child
      attribute_key_display_name_parent
      attribute_key_name_child
      attribute_key_name_parent
      attribute_list_valid_value_child
      attribute_list_valid_value_parent
      attribute_list_value_display_name_child
      attribute_list_value_display_name_parent
      attribute_list_value_sort_order_child
      attribute_list_value_sort_order_parent
      benefit_id
      child_operator_flag
      component_id
      component_source_table
      component_target_table
      component_type
      country
      user_id
      __typename
    }
  }
`;
export const filterBenefitFeature = /* GraphQL */ `
  mutation FilterBenefitFeature($benefit_plan_id: String) {
    filterBenefitFeature(benefit_plan_id: $benefit_plan_id) {
      benefit_plan_id
      benefit_plan_name
      as_of_date
      attribute_category
      attribute_key
      attribute_name
      attribute_value
      feature_create_trans_id
      file_url
      __typename
    }
  }
`;
export const filterAcropolisChatPrompts = /* GraphQL */ `
  mutation FilterAcropolisChatPrompts($topic: String) {
    filterAcropolisChatPrompts(topic: $topic) {
      topic
      prompt_id
      prompt_text
      __typename
    }
  }
`;
export const getDasboardEmbedURL = /* GraphQL */ `
  mutation GetDasboardEmbedURL($user_data: getDasboardEmbedURLInput) {
    getDasboardEmbedURL(user_data: $user_data)
  }
`;
export const geteFormTemplateFilterInvoice = /* GraphQL */ `
  mutation GeteFormTemplateFilterInvoice($template_id: String) {
    geteFormTemplateFilterInvoice(template_id: $template_id) {
      administrator_comments
      amount
      benefit_display_name
      benefit_id
      company_id
      company_name
      cost_category
      cost_category_display_name
      cost_type
      cost_type_display_name
      country
      country_display_name
      currency_code
      eform_destination_table
      sub_plan_id
      sub_plan_id_display_name
      template_display_name
      template_id
      template_type_display_name
      template_type_id
      userid
      vendor
      __typename
    }
  }
`;
export const geteFormTemplateMedicalDiagnosis = /* GraphQL */ `
  mutation GeteFormTemplateMedicalDiagnosis($template_id: String) {
    geteFormTemplateMedicalDiagnosis(template_id: $template_id) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      diagnosis_type
      diagnosis_type_display_name
      spend
      claim_volume
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const geteFormTemplateGeneralUsage = /* GraphQL */ `
  mutation GeteFormTemplateGeneralUsage($template_id: String) {
    geteFormTemplateGeneralUsage(template_id: $template_id) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const geteFormTemplateOperationalPerformance = /* GraphQL */ `
  mutation GeteFormTemplateOperationalPerformance($template_id: String) {
    geteFormTemplateOperationalPerformance(template_id: $template_id) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      sla_key
      sla_description
      sla_value
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const geteFormTemplateHeadcount = /* GraphQL */ `
  mutation GeteFormTemplateHeadcount($template_id: String) {
    geteFormTemplateHeadcount(template_id: $template_id) {
      template_id
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      benefit_id
      benefit_display_name
      country
      country_display_name
      vendor
      headcount_type
      headcount_type_display_name
      amount
      administrator_comments
      eform_destination_table
      userid
      __typename
    }
  }
`;
export const updateCompany = /* GraphQL */ `
  mutation UpdateCompany(
    $company_country_hq: String
    $company_id: String
    $company_name: String
    $company_state_hq: String
    $company_status: String
    $industry_id: String
    $is_exclude_from_benchmarks: String
    $poc_email: String
    $poc_role: String
    $employer_size: String
    $organization_id: String
    $primary_poc: String
    $sub_industry_id: String
    $userid: String
  ) {
    updateCompany(
      company_country_hq: $company_country_hq
      company_id: $company_id
      company_name: $company_name
      company_state_hq: $company_state_hq
      company_status: $company_status
      industry_id: $industry_id
      is_exclude_from_benchmarks: $is_exclude_from_benchmarks
      poc_email: $poc_email
      poc_role: $poc_role
      employer_size: $employer_size
      organization_id: $organization_id
      primary_poc: $primary_poc
      sub_industry_id: $sub_industry_id
      userid: $userid
    ) {
      as_of_date
      company_country_hq
      company_country_hq_intermediate_region
      company_country_hq_name
      company_country_hq_region_name
      company_country_hq_sub_region_name
      company_country_hq_two_digit_iso_code
      company_id
      company_name
      company_state_hq
      company_state_hq_name
      company_status
      industry_id
      industry_name
      is_exclude_from_benchmarks
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      employer_size
      employer_size_display_name
      oranization_id
      organization_name
      userid
      __typename
    }
  }
`;
export const updateIntegrateDetails = /* GraphQL */ `
  mutation UpdateIntegrateDetails(
    $as_of_date: String
    $attribute_key: String
    $attribute_value: String
    $company_id: String
    $country_code: String
    $template_display_name: String
    $template_id: String
    $template_status: String
    $template_type_id: String
    $userid: String
  ) {
    updateIntegrateDetails(
      as_of_date: $as_of_date
      attribute_key: $attribute_key
      attribute_value: $attribute_value
      company_id: $company_id
      country_code: $country_code
      template_display_name: $template_display_name
      template_id: $template_id
      template_status: $template_status
      template_type_id: $template_type_id
      userid: $userid
    ) {
      as_of_date
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      company_id
      company_id_status
      company_name
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      intermediate_region_name
      region_name
      sub_region_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      userid
      __typename
    }
  }
`;
export const updateIntegrateKeyValueColumnMap = /* GraphQL */ `
  mutation UpdateIntegrateKeyValueColumnMap(
    $as_of_date: String
    $attribute_key: String
    $attribute_source_value: String
    $attribute_target_value: String
    $company_id: String
    $country_code: String
    $filename: String
    $load_dt: String
    $source_column: String
    $target_column: String
    $template_id: String
    $template_type: String
    $userid: String
  ) {
    updateIntegrateKeyValueColumnMap(
      as_of_date: $as_of_date
      attribute_key: $attribute_key
      attribute_source_value: $attribute_source_value
      attribute_target_value: $attribute_target_value
      company_id: $company_id
      country_code: $country_code
      filename: $filename
      load_dt: $load_dt
      source_column: $source_column
      target_column: $target_column
      template_id: $template_id
      template_type: $template_type
      userid: $userid
    ) {
      as_of_date
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      company_id
      company_id_status
      company_name
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      intermediate_region_name
      region_name
      sub_region_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      userid
      __typename
    }
  }
`;
export const updateIntegrateTemplateColumnMap = /* GraphQL */ `
  mutation UpdateIntegrateTemplateColumnMap(
    $as_of_date: String
    $company_id: String
    $country_code: String
    $filename: String
    $load_dt: String
    $source_column_value_1: String
    $source_column_value_10: String
    $source_column_value_2: String
    $source_column_value_3: String
    $source_column_value_4: String
    $source_column_value_5: String
    $source_column_value_6: String
    $source_column_value_7: String
    $source_column_value_8: String
    $source_column_value_9: String
    $target_column_value: String
    $template_id: String
    $template_type_id: String
    $userid: String
  ) {
    updateIntegrateTemplateColumnMap(
      as_of_date: $as_of_date
      company_id: $company_id
      country_code: $country_code
      filename: $filename
      load_dt: $load_dt
      source_column_value_1: $source_column_value_1
      source_column_value_10: $source_column_value_10
      source_column_value_2: $source_column_value_2
      source_column_value_3: $source_column_value_3
      source_column_value_4: $source_column_value_4
      source_column_value_5: $source_column_value_5
      source_column_value_6: $source_column_value_6
      source_column_value_7: $source_column_value_7
      source_column_value_8: $source_column_value_8
      source_column_value_9: $source_column_value_9
      target_column_value: $target_column_value
      template_id: $template_id
      template_type_id: $template_type_id
      userid: $userid
    ) {
      as_of_date
      attribute_key
      attribute_key_description
      attribute_key_display_name
      attribute_list_valid_value
      attribute_list_value_display_name
      attribute_list_value_sort_order
      company_id
      company_id_status
      company_name
      component_source_table
      component_target_table
      component_type
      country_code
      country_name
      intermediate_region_name
      region_name
      sub_region_name
      template_display_name
      template_id
      template_status
      template_type_display_name
      template_type_id
      userid
      __typename
    }
  }
`;
export const updatePublishedDemographicProviderReconDetails = /* GraphQL */ `
  mutation UpdatePublishedDemographicProviderReconDetails(
    $publish_recon_data: [updatePublishedDemographicProviderReconDetailsInput]
    $user_id: String
  ) {
    updatePublishedDemographicProviderReconDetails(
      publish_recon_data: $publish_recon_data
      user_id: $user_id
    )
  }
`;
export const updateReportReconDetails = /* GraphQL */ `
  mutation UpdateReportReconDetails(
    $last_publish_dt: String
    $publish_status: String
    $publish_userid: String
    $report_request_id: String
  ) {
    updateReportReconDetails(
      last_publish_dt: $last_publish_dt
      publish_status: $publish_status
      publish_userid: $publish_userid
      report_request_id: $report_request_id
    )
  }
`;
export const updateReportReconEnrollmentSysToProviderPublishedDetails = /* GraphQL */ `
  mutation UpdateReportReconEnrollmentSysToProviderPublishedDetails(
    $publish_recon_data: [updateReportReconEnrollmentSysToProviderPublishedDetailsInput]
    $user_id: String
  ) {
    updateReportReconEnrollmentSysToProviderPublishedDetails(
      publish_recon_data: $publish_recon_data
      user_id: $user_id
    )
  }
`;
export const updateReportReconFundingDepositsToPayrollPublishedDetails = /* GraphQL */ `
  mutation UpdateReportReconFundingDepositsToPayrollPublishedDetails(
    $publish_recon_data: [updateReportReconFundingDepositsToPayrollPublishedDetailsInput]
    $user_id: String
  ) {
    updateReportReconFundingDepositsToPayrollPublishedDetails(
      publish_recon_data: $publish_recon_data
      user_id: $user_id
    )
  }
`;
export const deleteCompanyDetails = /* GraphQL */ `
  mutation DeleteCompanyDetails(
    $delete_company_details: [DeleteCompanyInput]
    $userid: String
  ) {
    deleteCompanyDetails(
      delete_company_details: $delete_company_details
      userid: $userid
    )
  }
`;
export const deleteTemplateDetails = /* GraphQL */ `
  mutation DeleteTemplateDetails(
    $delete_template_details: [DeleteTemplateInput]
    $userid: String
  ) {
    deleteTemplateDetails(
      delete_template_details: $delete_template_details
      userid: $userid
    )
  }
`;
export const deleteBenefitDetails = /* GraphQL */ `
  mutation DeleteBenefitDetails(
    $delete_benefit_details: [DeleteBenefitPlanInput]
    $userid: String
  ) {
    deleteBenefitDetails(
      delete_benefit_details: $delete_benefit_details
      userid: $userid
    )
  }
`;
export const deleteBenefitFeatureDetails = /* GraphQL */ `
  mutation DeleteBenefitFeatureDetails(
    $delete_benefit_features: [DeleteBenefitFeatureInput]
    $userid: String
  ) {
    deleteBenefitFeatureDetails(
      delete_benefit_features: $delete_benefit_features
      userid: $userid
    )
  }
`;
export const deleteFileDetails = /* GraphQL */ `
  mutation DeleteFileDetails(
    $delete_file_details: [DeleteFileInput]
    $userid: String
  ) {
    deleteFileDetails(
      delete_file_details: $delete_file_details
      userid: $userid
    )
  }
`;
export const acropolischatresolver = /* GraphQL */ `
  mutation Acropolischatresolver($chat_input: [createChatInput]) {
    acropolischatresolver(chat_input: $chat_input)
  }
`;
export const readCompanyDetailsFilter = /* GraphQL */ `
  mutation ReadCompanyDetailsFilter($company_id: String) {
    readCompanyDetailsFilter(company_id: $company_id) {
      as_of_date
      company_country_hq
      company_country_hq_intermediate_region
      company_country_hq_name
      company_country_hq_region_name
      company_country_hq_sub_region_name
      company_country_hq_two_digit_iso_code
      company_id
      company_name
      company_state_hq
      company_state_hq_name
      company_status
      industry_id
      industry_name
      is_exclude_from_benchmarks
      poc_email
      poc_role
      primary_poc
      sub_industry_id
      sub_industry_name
      employer_size
      employer_size_display_name
      oranization_id
      organization_name
      userid
      __typename
    }
  }
`;
export const createAcropolisChat = /* GraphQL */ `
  mutation CreateAcropolisChat($input: CreateAcropolisChatInput!) {
    createAcropolisChat(input: $input)
  }
`;
export const createAcropolisLLMResponse = /* GraphQL */ `
  mutation CreateAcropolisLLMResponse($input: AcropolisLLMResponseInput) {
    createAcropolisLLMResponse(input: $input) {
      session_id
      timestamp
      llm_response
      __typename
    }
  }
`;
export const createAcropolisLLMSource = /* GraphQL */ `
  mutation CreateAcropolisLLMSource($input: AcropolisLLMSourceInput) {
    createAcropolisLLMSource(input: $input) {
      session_id
      timestamp
      source_input {
        source_name
        file_url
        source_url
        source_publish_date
        __typename
      }
      __typename
    }
  }
`;
export const integrateTemplateEformFilter = /* GraphQL */ `
  mutation IntegrateTemplateOverview($userid: String) {
    IntegrateTemplateEformFilter(userid: $userid) {
      as_of_date
      template_id
      template_status
      template_display_name
      template_type_id
      template_type_display_name
      company_id
      company_name
      company_id_status
      country_code
      country_name
      region_name
      sub_region_name
      intermediate_region_name
      userid
      rn
      data_source
      __typename
    }
  }
`;